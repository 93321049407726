// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import Swiper JS
import Swiper, { Navigation, Pagination } from 'swiper';

const swiper = new Swiper('.js-swiper--standard', {
  speed: 400,
  loop: true,

  modules: [Navigation, Pagination],
  // Pagination fraction numbers
  pagination: {
    el: ".swiper-pagination-fraction",
    type: "fraction",
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

});
let swiper_hero = new Swiper(".swiper", {
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  scrollbar: {
    el: ".swiper-scrollbar",
  },
});
const swiper_cards = new Swiper('.js-swiper--cards', {
  speed: 400,
  loop: true,
  slidesPerView: 1.1,
  spaceBetween: 20,

  breakpoints: {
    768: {
      slidesPerView: 2.2,
      spaceBetween: 48,
    },
    992: {
      slidesPerView: 3.3,
      spaceBetween: 60,
    },
    1200: {
      slidesPerView: 3.3,
      spaceBetween: 78,
    },
  },

  modules: [Navigation, Pagination],
  // Pagination fraction numbers
  pagination: {
    el: ".swiper-pagination-fraction",
    type: "fraction",
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

});

// const swiper_slider = new Swiper(".rsce_cs_008", {
//   slidesPerView: 1.3,
//   spaceBetween: 24,
//   loop: true,
//   centeredSlides: true,
//   pagination: {
//     el: '.swiper-pagination',
//     type: 'bullets',
//     clickable: true
//   },
//   navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
//   scrollbar: {
//     el: ".swiper-scrollbar",
//     draggable: true,
//   },
//   breakpoints: {
//     768: {
//       slidesPerView: 2,
//       centeredSlides: false,
//     },
//     992: {
//       slidesPerView: 3,
//       centeredSlides: false,
//     },
//     1200: {
//       slidesPerView: 4,
//       centeredSlides: false,
//     }
//   }
// });

const image_slider = new Swiper(".js-swiper-image-slider", {
  speed: 400,
  loop: true,

  modules: [Navigation, Pagination],
  // Pagination fraction numbers
  pagination: {
    el: ".swiper-pagination-fraction",
    type: "fraction",
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

export default Swiper;