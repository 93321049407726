/***** rsce_cs_002 *****/
let cs002;

// encapsulate element js
cs002 = function () {

  function setScrollPosition() {
    let selector = ".clac-002-scroll-position .percent";
    let scrollPosition = document.querySelector(selector);

    // do nothing if element not exists
    if (!scrollPosition) {
      return;
    }

    // get max scroll position
    let maxPosition = Math.max(document.body.scrollHeight, document.body.offsetHeight,
      document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);

    // calc percent and handle rounding problems at 100 percent
    let percent = (document.documentElement.scrollTop / (maxPosition - window.innerHeight)) * 100;
    if (percent > 99.9) {
      percent = 100;
    }

    // set percent
    scrollPosition.style.width = percent + "%";
  }

  // on scroll
  window.addEventListener("scroll", function () {
    setScrollPosition();
  });

  window.addEventListener("DOMContentLoaded", function () {
    setScrollPosition();
  });
}

// init
cs002();

export default cs002;